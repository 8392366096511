import React from 'react'
import Layout from "../components/Layout"
import Impressum from "../components/Impressum"
import {SEO} from "../components/Seo";

const impressum = () => {
    return (
        <Layout>
            <Impressum/>
        </Layout>
    )
}

export default impressum

export const Head = () => (
    <SEO title="Impressum | Möglichkeiten zur Darstellung des Umsatzkostenverfahrens in SAP" />
)
