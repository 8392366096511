import React from 'react'

const Impressum = () => {

    return (
        <div>
            <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
                <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                    <h1 className="font-semibold md:text-4xl text-2xl pb-4">Impressum</h1>
                    <p className="lg:text-2xl sm:text-lg text-sm">Espresso Tutorials GmbH</p>
                </div>
            </section>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
                <div className="w-full md:w-content md:pr-16 lg:pr-24">
                    <div className="w-full mt-9 article">
                        <div className="prose lg:prose-xl">
                            <p>Espresso Tutorials GmbH<br/>
                                Bahnhofstrasse 2<br/>
                                37130 Gleichen</p>
                            <p>Telefon: 0551 / 790 89 480<br/>
                                Telefax: 0551 / 790 89 481</p>
                            <p>E-Mail: info@espresso-tutorials.com<br/>
                                Geschäftsführer: Martin Munzel Jörg Siebert<br/>
                                Eintragung im Handelsregister Registergericht: Göttingen<br/>
                                Registernummer: B 201669<br/>
                                Umsatzsteuer-Identifikationsnummer gemäss Paragraph 27 a Umsatzsteuergesetz: DE278918013</p>
                            <p><strong>Haftung für Inhalte</strong><br/>
                                Die Inhalte unserer Seiten wurden mit grösster Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäss Paragraph 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach Paragraph 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                            <p><strong>Haftung für Links</strong><br/>
                                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstösse überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                            <p><strong>Urheberrecht</strong><br/>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung ausserhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                        </div>
                    </div>
                </div>
                <div className="w-full w-sidebar mt-12 md:mt-0"></div>
            </div>
        </div>
    )
}

export default Impressum
